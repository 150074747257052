import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isAuthenticated: localStorage.getItem("is_auth") || false,
    apiToken: localStorage.getItem("api_token") || "",
  },
  mutations: {
    setAuth(state, payload) {
      // Store the token in local storeage
      localStorage.setItem("api_token", payload.apiToken);
      localStorage.setItem("is_auth", payload.isAuth);
      // Set the states
      state.isAuthenticated = payload.isAuth;
      state.apiToken = payload.apiToken;
    },
  },
  actions: {
    login({ commit }, authToken) {
      // Perform login logic, then:
      const payload = {
        isAuth: true,
        apiToken: authToken,
      };
      commit("setAuth", payload);
    },
    logout({ commit }) {
      commit("setAuth", false);
      this.state.isAuthenticated = false;
      this.state.apiToken = "";
      localStorage.removeItem("is_auth");
      localStorage.removeItem("api_token");
    },
  },
  getters: {
    isAuthenticated(state) {
      if (state.isAuthenticated && state.apiToken != "") {
        return true;
      }
      return false;
    },
    authToken(state) {
      return state.apiToken;
    },
  },
});

export default store;
